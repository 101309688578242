import React, { useRef } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const Comunidad = ({ s }) => {
	const intl = useIntl();

	var sliderBoards = useRef(null);
	var sliderBeaches = useRef(null);
	var sliderHotels = useRef(null);
	var sliderSpots = useRef(null);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="community">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title="Comunidad" />
					<main className="page">
						<article className="bg-img">
							<h1 className="white-text prequel-font">Euskadi</h1>
							<picture>
								<source srcSet="/img/bg/community-desktop.jpg" media="(min-width: 768px)" />
								<img srcSet="/img/bg/community.jpg" alt={``} />
							</picture>
						</article>
						<article className="box boards">
							<ul className="breadcrumbs white-bg">
								<li className="link">
									<Link to="/" className="ltblue-text-02">
										Home
									</Link>
								</li>
								<li className="active">
									<p className="lightgray-text-07">Euskadi</p>
								</li>
							</ul>
							<div className="wrapper-text box blue-bg">
								<div className="text">
									<p className="white-text">
										{Tools.convertString(s.comunidad?.text)}
									</p>
								</div>
								<div className="slider slider-boards">
									<Slider
										ref={(c) => (sliderBoards = c)}
										dots={true}
										infinite={true}
										speed={500}
										arrows={false}
										slidesToShow={3}
										slidesToScroll={3}
										responsive={[
											{
												breakpoint: 1199,
												settings: {
													slidesToShow: 2,
													slidesToScroll: 2,
													initialSlide: 2,
												},
											},
											{
												breakpoint: 767,
												settings: {
													slidesToShow: 1,
													slidesToScroll: 1,
												},
											},
										]}
									>
										<div className="slider-boards-item">
											<div className="wrapper">
												<div className="slider-boards-content">
													<div className="slider-item-boards-wrapper">
														<img
															className="board-img"
															src="https://placeimg.com/768/425/animals"
															alt={``}
														/>
													</div>
													<div className="slider-boards-details white-bg">
														<small className="lightgray-text-05 location">Zarautz</small>
														<small className="lightgray-bg-03 white-text board-type">Longboard</small>
														<p className="black-text-01 description">
															Short casi nueva al lado de la playad de Sopelana y lorem ipsum
														</p>
														<strong className="black-text-01 price">€10 hora</strong>
														<small className="lightgray-text-05 rate">
															<img src="/img/rate.svg" />
															4,5<span>(21)</span>
														</small>
													</div>
												</div>
											</div>
										</div>
										<div className="slider-boards-item">
											<div className="wrapper">
												<div className="slider-boards-content">
													<div className="slider-item-boards-wrapper">
														<img
															className="board-img"
															src="https://placeimg.com/768/425/animals"
															alt={``}
														/>
													</div>
													<div className="slider-boards-details white-bg">
														<small className="lightgray-text-05 location">Zarautz</small>
														<small className="lightgray-bg-03 white-text board-type">Longboard</small>
														<p className="black-text-01 description">
															Short casi nueva al lado de la playad de Sopelana y lorem ipsum
														</p>
														<strong className="black-text-01 price">€10 hora</strong>
														<small className="lightgray-text-05 rate">
															<img src="/img/rate.svg" />
															4,5<span>(21)</span>
														</small>
													</div>
												</div>
											</div>
										</div>
										<div className="slider-boards-item">
											<div className="wrapper">
												<div className="slider-boards-content">
													<div className="slider-item-boards-wrapper">
														<img
															className="board-img"
															src="https://placeimg.com/768/425/animals"
															alt={``}
														/>
													</div>
													<div className="slider-boards-details white-bg">
														<small className="lightgray-text-05 location">Zarautz</small>
														<small className="lightgray-bg-03 white-text board-type">Longboard</small>
														<p className="black-text-01 description">
															Short casi nueva al lado de la playad de Sopelana y lorem ipsum
														</p>
														<strong className="black-text-01 price">€10 hora</strong>
														<small className="lightgray-text-05 rate">
															<img src="/img/rate.svg" />
															4,5<span>(21)</span>
														</small>
													</div>
												</div>
											</div>
										</div>
										<div className="slider-boards-item">
											<div className="wrapper">
												<div className="slider-boards-content">
													<div className="slider-item-boards-wrapper">
														<img
															className="board-img"
															src="https://placeimg.com/768/425/animals"
															alt={``}
														/>
													</div>
													<div className="slider-boards-details white-bg">
														<small className="lightgray-text-05 location">Zarautz</small>
														<small className="lightgray-bg-03 white-text board-type">Longboard</small>
														<p className="black-text-01 description">
															Short casi nueva al lado de la playad de Sopelana y lorem ipsum
														</p>
														<strong className="black-text-01 price">€10 hora</strong>
														<small className="lightgray-text-05 rate">
															<img src="/img/rate.svg" />
															4,5<span>(21)</span>
														</small>
													</div>
												</div>
											</div>
										</div>
										<div className="slider-boards-item">
											<div className="wrapper">
												<div className="slider-boards-content">
													<div className="slider-item-boards-wrapper">
														<img
															className="board-img"
															src="https://placeimg.com/768/425/animals"
															alt={``}
														/>
													</div>
													<div className="slider-boards-details white-bg">
														<small className="lightgray-text-05 location">Zarautz</small>
														<small className="lightgray-bg-03 white-text board-type">Longboard</small>
														<p className="black-text-01 description">
															Short casi nueva al lado de la playad de Sopelana y lorem ipsum
														</p>
														<strong className="black-text-01 price">€10 hora</strong>
														<small className="lightgray-text-05 rate">
															<img src="/img/rate.svg" />
															4,5<span>(21)</span>
														</small>
													</div>
												</div>
											</div>
										</div>
										<div className="slider-boards-item">
											<div className="wrapper">
												<div className="slider-boards-content">
													<div className="slider-item-boards-wrapper">
														<img
															className="board-img"
															src="https://placeimg.com/768/425/animals"
															alt={``}
														/>
													</div>
													<div className="slider-boards-details white-bg">
														<small className="lightgray-text-05 location">Zarautz</small>
														<small className="lightgray-bg-03 white-text board-type">Longboard</small>
														<p className="black-text-01 description">
															Short casi nueva al lado de la playad de Sopelana y lorem ipsum
														</p>
														<strong className="black-text-01 price">€10 hora</strong>
														<small className="lightgray-text-05 rate">
															<img src="/img/rate.svg" />
															4,5<span>(21)</span>
														</small>
													</div>
												</div>
											</div>
										</div>
									</Slider>
									<div className="slider-arrows hide-xs">
										<span className="arrow left" onClick={() => sliderBoards.slickPrev()}></span>
										<span className="arrow right" onClick={() => sliderBoards.slickNext()}></span>
									</div>
								</div>
								<Link to="/anuncios" className="view-more align-center margin-auto white-text">
									Ver más tablas
								</Link>
							</div>
						</article>

						<div className="place black-bg-03">
							<div className="wrapper box">
								<article className="white-text box">
									<h5 className="red-text prequel-font hide-xs">EUSKADI</h5>
									<h3 className="white-text">Una tierra rica en el norte</h3>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
										ut labore et dolore magna aliqua ut enim ad minim veniam, quis nostrud exercitation
										ullamco laboris nisi ut aliquip ex ea commodo consequat.
									</p>
								</article>
								<article>
									<img src="/img/bg/euskadi.jpg" alt={``} />
								</article>
							</div>
						</div>

						<div className="beaches blue-bg">
							<div className="wrapper box">
								<article className="white-text box">
									<h5 className="red-text prequel-font hide-xs">Lorem ipsum</h5>
									<div className="wrapper-title">
										<h4 className="prequel-font">Las Playas</h4>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut.
										</p>
									</div>
								</article>
								<div className="box slider slider-beaches">
									<div className="slider-beaches-item">
										<Slider
											ref={(c) => (sliderBeaches = c)}
											dots={true}
											infinite={true}
											speed={500}
											arrows={false}
											slidesToShow={3}
											slidesToScroll={3}
											responsive={[
												{
													breakpoint: 1199,
													settings: {
														slidesToShow: 2,
														slidesToScroll: 2,
														initialSlide: 2,
													},
												},
												{
													breakpoint: 767,
													settings: {
														slidesToShow: 1,
														slidesToScroll: 1,
													},
												},
											]}
										>
											<div className="slider-beaches-item">
												<div className="wrapper">
													<div className="slider-beaches-content">
														<div className="slider-item-beaches-wrapper">
															<img
																className="beach-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-beaches-details white-bg">
															<p className="beach-title black-text-01">Zarautz</p>
															<p className="beach-description lightgray-text-08">
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
																tempor incididunt ut
															</p>
															<a href="/" className="webcam button black-bg-01 white-text">
																Webcam
															</a>
															<a href="/" className="waves button white-bg black-text-01">
																Ver oleaje
															</a>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beaches-item">
												<div className="wrapper">
													<div className="slider-beaches-content">
														<div className="slider-item-beaches-wrapper">
															<img
																className="beach-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-beaches-details white-bg">
															<p className="beach-title black-text-01">Zarautz</p>
															<p className="beach-description lightgray-text-08">
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
																tempor incididunt ut
															</p>
															<a href="/" className="webcam button black-bg-01 white-text">
																Webcam
															</a>
															<a href="/" className="waves button white-bg black-text-01">
																Ver oleaje
															</a>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beaches-item">
												<div className="wrapper">
													<div className="slider-beaches-content">
														<div className="slider-item-beaches-wrapper">
															<img
																className="beach-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-beaches-details white-bg">
															<p className="beach-title black-text-01">Zarautz</p>
															<p className="beach-description lightgray-text-08">
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
																tempor incididunt ut
															</p>
															<a href="/" className="webcam button black-bg-01 white-text">
																Webcam
															</a>
															<a href="/" className="waves button white-bg black-text-01">
																Ver oleaje
															</a>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beaches-item">
												<div className="wrapper">
													<div className="slider-beaches-content">
														<div className="slider-item-beaches-wrapper">
															<img
																className="beach-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-beaches-details white-bg">
															<p className="beach-title black-text-01">Zarautz</p>
															<p className="beach-description lightgray-text-08">
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
																tempor incididunt ut
															</p>
															<a href="/" className="webcam button black-bg-01 white-text">
																Webcam
															</a>
															<a href="/" className="waves button white-bg black-text-01">
																Ver oleaje
															</a>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beaches-item">
												<div className="wrapper">
													<div className="slider-beaches-content">
														<div className="slider-item-beaches-wrapper">
															<img
																className="beach-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-beaches-details white-bg">
															<p className="beach-title black-text-01">Zarautz</p>
															<p className="beach-description lightgray-text-08">
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
																tempor incididunt ut
															</p>
															<a href="/" className="webcam button black-bg-01 white-text">
																Webcam
															</a>
															<a href="/" className="waves button white-bg black-text-01">
																Ver oleaje
															</a>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beaches-item">
												<div className="wrapper">
													<div className="slider-beaches-content">
														<div className="slider-item-beaches-wrapper">
															<img
																className="beach-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-beaches-details white-bg">
															<p className="beach-title black-text-01">Zarautz</p>
															<p className="beach-description lightgray-text-08">
																Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
																tempor incididunt ut
															</p>
															<a href="/" className="webcam button black-bg-01 white-text">
																Webcam
															</a>
															<a href="/" className="waves button white-bg black-text-01">
																Ver oleaje
															</a>
														</div>
													</div>
												</div>
											</div>
										</Slider>
									</div>
									<div className="slider-arrows hide-xs">
										<span className="arrow left" onClick={() => sliderBeaches.slickPrev()}></span>
										<span className="arrow right" onClick={() => sliderBeaches.slickNext()}></span>
									</div>
								</div>
							</div>
						</div>

						<div className="hotels white-bg">
							<div className="wrapper box">
								<article className="black-text-01 box">
									<h5 className="red-text prequel-font hide-xs">Lorem ipsum</h5>
									<div className="wrapper-title">
										<h4 className="prequel-font">HOTELES</h4>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut labore et dolore magna aliqua sed do eiusmod tempor incididunt.
										</p>
									</div>
								</article>
								<div className="box slider slider-hotels">
									<div className="slider-hotels-item">
										<Slider
											ref={(c) => (sliderHotels = c)}
											dots={true}
											infinite={true}
											speed={500}
											arrows={false}
											slidesToShow={3}
											slidesToScroll={3}
											responsive={[
												{
													breakpoint: 1199,
													settings: {
														slidesToShow: 2,
														slidesToScroll: 2,
														initialSlide: 2,
													},
												},
												{
													breakpoint: 767,
													settings: {
														slidesToShow: 1,
														slidesToScroll: 1,
													},
												},
											]}
										>
											<div className="slider-beotels-item">
												<div className="wrapper">
													<div className="slider-beachesotelscontent">
														<div className="slider-item-hotels-wrapper">
															<img
																className="hotel-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-hotels-details black-bg-01">
															<p className="hotel-title white-text">Parador de Limpias</p>
															<p className="hotel-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beotels-item">
												<div className="wrapper">
													<div className="slider-beachesotelscontent">
														<div className="slider-item-hotels-wrapper">
															<img
																className="hotel-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-hotels-details black-bg-01">
															<p className="hotel-title white-text">Parador de Limpias</p>
															<p className="hotel-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beotels-item">
												<div className="wrapper">
													<div className="slider-beachesotelscontent">
														<div className="slider-item-hotels-wrapper">
															<img
																className="hotel-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-hotels-details black-bg-01">
															<p className="hotel-title white-text">Parador de Limpias</p>
															<p className="hotel-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beotels-item">
												<div className="wrapper">
													<div className="slider-beachesotelscontent">
														<div className="slider-item-hotels-wrapper">
															<img
																className="hotel-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-hotels-details black-bg-01">
															<p className="hotel-title white-text">Parador de Limpias</p>
															<p className="hotel-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beotels-item">
												<div className="wrapper">
													<div className="slider-beachesotelscontent">
														<div className="slider-item-hotels-wrapper">
															<img
																className="hotel-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-hotels-details black-bg-01">
															<p className="hotel-title white-text">Parador de Limpias</p>
															<p className="hotel-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-beotels-item">
												<div className="wrapper">
													<div className="slider-beachesotelscontent">
														<div className="slider-item-hotels-wrapper">
															<img
																className="hotel-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-hotels-details black-bg-01">
															<p className="hotel-title white-text">Parador de Limpias</p>
															<p className="hotel-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>
										</Slider>
									</div>
									<div className="slider-arrows hide-xs">
										<span className="arrow left" onClick={() => sliderHotels.slickPrev()}></span>
										<span className="arrow right" onClick={() => sliderHotels.slickNext()}></span>
									</div>
								</div>
							</div>
						</div>

						<div className="spots black-bg-03">
							<div className="wrapper box">
								<article className="white-text box">
									<h5 className="red-text prequel-font hide-xs">Lorem ipsum</h5>
									<div className="wrapper-title">
										<h4 className="prequel-font">SITIOS UNICOS DE EUSKADI</h4>
										<p>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut.
										</p>
									</div>
								</article>
								<div className="box slider slider-spots">
									<div className="slider-spots-item">
										<Slider
											ref={(c) => (sliderSpots = c)}
											dots={true}
											infinite={true}
											speed={500}
											arrows={false}
											slidesToShow={3}
											slidesToScroll={3}
											responsive={[
												{
													breakpoint: 1199,
													settings: {
														slidesToShow: 2,
														slidesToScroll: 2,
														initialSlide: 2,
													},
												},
												{
													breakpoint: 767,
													settings: {
														slidesToShow: 1,
														slidesToScroll: 1,
													},
												},
											]}
										>
											<div className="slider-spots-item">
												<div className="wrapper">
													<div className="slider-spots-content">
														<div className="slider-item-spots-wrapper">
															<img
																className="spot-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-spots-details white-bg">
															<p className="spot-title black-text-01">San Juan de Gaztelugatxe</p>
															<p className="spot-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-spots-item">
												<div className="wrapper">
													<div className="slider-spots-content">
														<div className="slider-item-spots-wrapper">
															<img
																className="spot-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-spots-details white-bg">
															<p className="spot-title black-text-01">San Juan de Gaztelugatxe</p>
															<p className="spot-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-spots-item">
												<div className="wrapper">
													<div className="slider-spots-content">
														<div className="slider-item-spots-wrapper">
															<img
																className="spot-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-spots-details white-bg">
															<p className="spot-title black-text-01">San Juan de Gaztelugatxe</p>
															<p className="spot-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-spots-item">
												<div className="wrapper">
													<div className="slider-spots-content">
														<div className="slider-item-spots-wrapper">
															<img
																className="spot-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-spots-details white-bg">
															<p className="spot-title black-text-01">San Juan de Gaztelugatxe</p>
															<p className="spot-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-spots-item">
												<div className="wrapper">
													<div className="slider-spots-content">
														<div className="slider-item-spots-wrapper">
															<img
																className="spot-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-spots-details white-bg">
															<p className="spot-title black-text-01">San Juan de Gaztelugatxe</p>
															<p className="spot-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>

											<div className="slider-spots-item">
												<div className="wrapper">
													<div className="slider-spots-content">
														<div className="slider-item-spots-wrapper">
															<img
																className="spot-img"
																src="https://placeimg.com/768/425/animals"
																alt={``}
															/>
														</div>
														<div className="slider-spots-details white-bg">
															<p className="spot-title black-text-01">San Juan de Gaztelugatxe</p>
															<p className="spot-description lightgray-text-08">
																Preciosa casa moontañesa ubicada en Limpias
															</p>
														</div>
													</div>
												</div>
											</div>
										</Slider>
									</div>
									<div className="slider-arrows hide-xs">
										<span className="arrow left" onClick={() => sliderSpots.slickPrev()}></span>
										<span className="arrow right" onClick={() => sliderSpots.slickNext()}></span>
									</div>
								</div>
							</div>
						</div>

						<article className="helper white-bg black-text-01 box">
							<p className="align-center">Con la colaboración de</p>
							<img className="margin-auto" src="/img/logo-euskadi.png" alt="" />
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Comunidad);
